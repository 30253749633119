<template>
  <div>
    <v-btn
      v-if="dnsMismatch"
      elevation="0"
      outlined
      x-small
      color="error"
      class="mr-4"
      :loading="loading"
      @click="loadModal"
    >
      <v-icon size="24">$alertwarning</v-icon>
      <span class="text--heading p-4 font-weight-bold">{{
        $t("message.dnsPropagationInProgress")
      }}</span>
    </v-btn>
    <v-btn
      v-else-if="
        !dnsMismatch && instance.getUserPrivileges('general.change_domain')
      "
      elevation="0"
      outlined
      x-small
      color="primary"
      class="mr-4"
      :loading="loading"
      @click="loadModal"
    >
      <span class="text--heading p-4 font-weight-bold">{{
        $t("button.setDomain.changeDomain")
      }}</span>
    </v-btn>
    <v-dialog
      ref="dialog"
      v-model="isOpen"
      transition="custom-dialog-transition"
    >
      <div class="card-overlay" @click="isOpen = !isOpen" />
      <v-card style="width: 560px">
        <v-card-title class="pb-6 flex justify-space-between align-center">
          <h4 class="font-weight-light">
            {{ $t("heading.instance.modal.setDomain.title") }}
          </h4>
          <v-btn icon x-small @click="isOpen = false">
            <v-icon size="24">$close</v-icon>
          </v-btn>
        </v-card-title>
        <template v-if="step === 0">
          <v-card-text>
            <v-form ref="form" @submit.prevent="nextStep">
              <v-expansion-panels v-model="panelOpen">
                <v-expansion-panel elevation="0">
                  <v-expansion-panel-header
                    class="h6 text--heading font-weight-bold"
                  >
                    {{
                      $t("heading.instance.modal.setDomain.set.domain.title")
                    }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ $t("form.label.domainName") }}
                    <a
                      class="float-right info--text"
                      v-if="orderDomainLink"
                      target="_blank"
                      :href="orderDomainLink"
                    >
                      {{ $t("button.domain.order") }}
                    </a>
                    <v-text-field
                      v-if="!panelOpen"
                      class="mt-2"
                      outlined
                      placeholder="mydomain.com"
                      v-model="clientDomainName"
                      hide-details=""
                      validate-on-blur
                      :rules="[
                        (v) =>
                          !!v ||
                          $t('validation.required', {
                            field: $t('form.label.domainName'),
                          }),
                        (v) =>
                          domainRegex.test(v) ||
                          $t('validation.valid', {
                            field: $t('form.label.domainName'),
                          }),
                      ]"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="freeSubdomains.length > 0">
                  <v-expansion-panel-header
                    class="h6 text--heading font-weight-bold"
                  >
                    {{
                      $t("heading.instance.modal.setDomain.set.subdomain.title")
                    }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-if="panelOpen === 1"
                      class="flex flex-row align-center"
                      style="display: flex"
                    >
                      <v-text-field
                        class=""
                        outlined
                        placeholder="mydomain"
                        v-model="freeDomainName"
                        hide-details=""
                        validate-on-blur
                        max-width="250px"
                        :rules="[
                          (v) =>
                            !!v ||
                            $t('validation.required', {
                              field: $t('general.subdomain'),
                            }),
                        ]"
                      />
                      <span class="mx-2 mb-6">.</span>
                      <v-select
                        outlined
                        :items="freeSubdomains"
                        v-model="freeDomainAffix"
                        hide-details=""
                        validate-on-blur
                        max-width="250px"
                        :rules="[
                          (v) =>
                            !!v ||
                            $t('validation.required', {
                              field: $t('form.label.domain'),
                            }),
                        ]"
                        :menu-props="{
                          offsetY: true,
                          nudgeBottom: '8px',
                          closeOnContentClick: false,
                          contentClass:
                            'custom-dropdown-select custom-dropdown-select--onboarding',
                        }"
                      />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-card-text>

          <v-card-actions class="d-flex flex-column pb-4 px-8 pt-6">
            <v-btn
              x-large
              elevation="0"
              color="primary"
              block
              :loading="submitting"
              @click="nextStep"
            >
              {{ $t("button.setDomain.useThisDomain") }}
            </v-btn>
            <v-btn
              x-large
              elevation="0"
              class="mx-0 mt-2"
              color="gray"
              text
              block
              @click="isOpen = false"
            >
              <span class="p-1 font-weight-light gray--text text--darken-1">
                {{ $t("button.cancel") }}
              </span>
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else-if="step === 1">
          <v-card-text class="pb-8">
            <div class="domain-container">
              <span class="p-3">
                {{ $t("heading.instance.modal.setDomain.config.subtitle") }}
                <span class="text--heading font-weight-600">
                  {{ instance.domain }}
                </span>
              </span>

              <v-btn
                color="primary"
                x-small
                outlined
                elevation="0"
                @click="step = 0"
                v-if="instance.getUserPrivileges('general.change_domain')"
              >
                {{ $t("button.change") }}
              </v-btn>
            </div>

            <DnsOptions
              :domain="instance.domain"
              :onboardingSubdomain="instance.onboarding_subdomain"
              :dnsSettings="instance.dns_settings"
              :ipv4Addresses="ipv4Addresses"
              :hasDnsServer="instance.service_has_dns"
              :nameservers="instance.dns_nameservers"
            />
          </v-card-text>
        </template>

        <template v-else-if="step === 2">
          <v-card-text>
            <template v-if="instance.plan_settings.domain_transfer_enabled">
              <h6 class="p-2 font-weight-light">
                <i18next
                  :translation="
                    $t('heading.onboarding.transferDomain.subtitle')
                  "
                >
                  <template #domain
                    ><span class="font-weight-600">{{
                      domainComputed
                    }}</span></template
                  >
                </i18next>
              </h6>
              <p class="p-3 mt-2">
                <i18next
                  :translation="
                    $t('heading.onboarding.transferDomain.message.possible')
                  "
                >
                  <template #recordType
                    ><span class="font-weight-800">{{
                      domainVerificationDetails.type
                    }}</span>
                  </template>
                  <template #domain
                    ><span class="font-weight-800">{{
                      domainComputed
                    }}</span></template
                  >
                </i18next>
              </p>
              <div class="recovery-code-box">
                {{ domainVerificationDetails.content }}
                <copy-text-button
                  :text="domainVerificationDetails.content"
                  :color="'primary'"
                  size="24"
                />
              </div>
            </template>
            <template v-else>
              <h6 class="p-2 font-weight-light">
                <i18next
                  :translation="
                    $t('heading.onboarding.transferDomain.subtitle')
                  "
                >
                  <template #domain
                    ><span class="font-weight-600">{{
                      domainComputed
                    }}</span></template
                  >
                </i18next>
              </h6>
              <p class="p-3 mt-2">
                {{ $t("heading.onboarding.transferDomain.message.impossible") }}
              </p>
            </template>
          </v-card-text>
          <v-card-actions class="d-flex flex-column pb-4 px-8 pt-6">
            <template v-if="instance.plan_settings.domain_transfer_enabled">
              <v-btn
                x-large
                elevation="0"
                color="primary"
                block
                :loading="submitting"
                @click="changeDomain"
              >
                {{ $t("button.transferNow") }}
              </v-btn>
              <v-btn
                x-large
                elevation="0"
                class="mx-0 mt-2"
                color="gray"
                text
                block
                @click="isOpen = false"
              >
                <span class="p-1 font-weight-light gray--text text--darken-1">
                  {{ $t("button.cancel") }}
                </span>
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                x-large
                elevation="0"
                color="primary"
                block
                @click="isOpen = false"
                >{{ $t("button.cancel") }}</v-btn
              >
            </template>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import CopyTextButton from "@/components/buttons/CopyTextButton.vue";
import DnsOptions from "../DnsOptions.vue";
export default {
  components: { CopyTextButton, DnsOptions },
  data: function () {
    return {
      isOpen: false,
      infoTab: 0,
      panelOpen: 0,
      dnsMismatch: false,
      step: 0,
      loading: false,
      clientDomainName: "",
      freeDomainAffix: "",
      freeDomainName: "",
      freeSubdomains: [],
      submitting: false,
      ipAddress: "",
      ipv4Addresses: [],
      nameservers: [],
      domainRegex:
        /[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,9}(:[0-9]{1,5})?(\/[a-zA-Z0-9_~-]+)*$/,
      //orderDomainLink: "",
      domainVerificationDetails: {},
    };
  },
  props: {
    instance: Object,
  },
  computed: {
    orderDomainLink() {
      return this.$store.state.home.user.order_domain_link;
    },
    domainComputed() {
      return this.panelOpen === 0
        ? this.clientDomainName
        : `${this.freeDomainName}.${this.freeDomainAffix}`;
    },
  },
  methods: {
    loadModal() {
      this.loading = true;
      return Api.cached()
        .get(`/instances/${this.instance.id}/change-domain/options`)
        .then((response) => {
          this.freeSubdomains = response.data.data.subdomains;
          this.freeDomainAffix = this.freeSubdomains[0];
          this.ipAddress = response.data.data.ip_address;
          this.ipv4Addresses = response.data.data.ipv4_addresses;
          this.nameservers = response.data.data.nameservers;
          //this.orderDomainLink = response.data.data.order_domain_link;
          this.isOpen = true;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async checkDnsPropagation() {

      this.loading = true;
      let result = await Api.dns().verifyDomainResolving(
        this.instance.domain,
        this.instance.onboarding_subdomain,
        this.instance.dns_settings,
        this.instance.service_has_dns,
        this.instance.host_ipv4_addresses,
        this.instance.dns_nameservers,
      );
      this.loading = false;
      this.dnsMismatch = !result;
      if (this.dnsMismatch) {
        this.$emit('dns-propagation-in-progress');
      }
      this.step = 1;
    },
    nextStep() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.instance.server_settings.domain_transfer_allowed) {
        this.verifyDomain();
        return;
      }

      this.changeDomain();
    },
    verifyDomain() {
      this.submitting = true;
      Api.get(
        `/services/${this.instance.service_id}/verify-domain?domain=${this.domainComputed}`
      )
        .then((response) => {
          if (response.data.verification_required) {
            this.domainVerificationDetails = response.data.dns_record;
            this.submitting = false;
            this.step = 2;
            return;
          }
          this.changeDomain();
        })
        .catch((error) => {
          this.submitting = false;
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        });
    },
    changeDomain() {
      if (this.panelOpen === 0) {
        this.submitting = true;
        Api.put(`/instances/${this.instance.id}/change-domain/domain`, {
          domain: this.clientDomainName,
        })
          .then((response) => {
            this.checkDnsPropagation().then(() => {
              this.$emit("instance-published", response.data.data);
              this.nameservers = response.data.data.dns_nameservers;
              this.step = 1;
            });
          })
          .catch((error) => {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: Api.getErrorMessage(error),
            });
          })
          .finally(() => {
            this.submitting = false;
          });
      } else {
        this.submitting = true;
        Api.put(`/instances/${this.instance.id}/change-domain/subdomain`, {
          subdomain: this.freeDomainName,
          domain: this.freeDomainAffix,
        })
          .then((response) => {
            this.checkDnsPropagation().then(() => {
              this.$emit("instance-published", response.data.data);
              this.isOpen = false;
            });
          })
          .catch((error) => {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: Api.getErrorMessage(error),
            });
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
  },
  watch: {
    isOpen: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  mounted() {
    this.checkDnsPropagation();
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  -webkit-line-clamp: unset;
}

.v-expansion-panel {
  border: 1px solid var(--v-gray-lighten2);

  &::before {
    display: none;
  }

  &::after {
    display: none;
  }

  &--active {
    border-color: var(--v-primary-base);
  }

  .v-expansion-panel-content::v-deep {
    .v-expansion-panel-content__wrap {
      padding-bottom: 0px;
    }
  }

  .v-expansion-panel-header {
    padding-left: 60px;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1 !important;
      background: white;
      border-radius: 20px;
    }

    &::before {
      transition: border-color 0.24s ease, background-color 0.24s ease;
      width: 20px;
      height: 20px;
      border: 1px solid var(--v-gray-base);
    }

    &::after {
      width: 10px;
      height: 10px;
      z-index: 10;
      left: 29px;
    }

    &:hover {
      &::before {
        border-color: #07c07e;
      }
    }

    &--active {
      &:before {
        border-color: #07c07e;
        background-color: #07c07e;
      }
    }
  }
}

.domain-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--v-gray-lighten2);
  padding: 16px 16px;
  margin-bottom: 16px;
}

.domain-info {
  border-radius: 6px;
  border: 1px solid var(--v-gray-lighten2);
  padding: 6px 16px 16px 16px;
  background: #fafafa;
  margin-bottom: 24px;
}

.recovery-code-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: var(--v-primary-base);
  background: rgba(7, 192, 126, 0.05);
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  word-wrap: anywhere;
  padding: 8px;
  min-height: 80px !important;
}
</style>