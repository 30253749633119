<template>
  <div v-if="displayDnsOptions.length">
    <h6
      v-if="!hideTitle && displayDnsOptions.length > 1"
      class="text--heading font-weight-bold mb-4"
    >
      {{ $t("heading.instance.modal.setDomain.config.title") }}
    </h6>
    <div>
      <v-expansion-panels v-model="panel" class="v-expansion-panels--dns p-sm" v-if="showARecordInfo || showCNAMERecordInfo || showNameserversInfo">
        <v-expansion-panel expand v-model="panel" v-if="showARecordInfo">
          <v-expansion-panel-header class="p-d font-weight-600" disable-icon-rotate>
              {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.title") }}
            <template v-slot:actions>
              <v-icon color="darken1" :size="16">$plus</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ol>
              <li>
                {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.first") }}
              </li>
              <li>
                {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.second") }}
                  <div class="well p-d pa-3 mt-1">
                    <span v-for="(ipAddress, index) in ipv4Addresses" :key="index">
                      {{ $t("general.name") }}:
                      <strong>{{ domain }}</strong>
                      ({{ $t("general.or") }} <strong>@</strong>) <br />{{ $t("general.type") }}:
                      <strong>A</strong>
                      <br />{{ $t("general.address") }}:
                      <strong>{{ ipAddress }}</strong>
                    </span>
                  </div>
                  <div class="well p-d pa-3 mt-1">
                    {{ $t("general.name") }}:
                    <strong>www.{{ domain }}</strong>
                    <br />{{ $t("general.type") }}:
                    <strong>CNAME</strong>
                    <br />{{ $t("general.address") }}:
                    <strong>{{ domain }}</strong>
                  </div>
              </li>
              <li>
                {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.third") }}
              </li>
            </ol>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-model="panel" v-if="showCNAMERecordInfo">
          <v-expansion-panel-header class="p-d font-weight-600" disable-icon-rotate>
              {{ $t("heading.instance.modal.setDomain.config.tab.cnameRecords.title") }}
            <template v-slot:actions>
              <v-icon color="gray" :size="16">$plus</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ol>
              <li>
                {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.first") }}
              </li>
              <li>
                {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.second") }}
                <div class="well p-d pa-3 mt-1">
                  <span>
                    {{ $t("general.name") }}:
                    <strong>{{ domain }}</strong>
                    ({{ $t("general.or") }} <strong>@</strong>) <br />{{ $t("general.type") }}:
                    <strong>CNAME</strong>
                    <br />{{ $t("general.address") }}:
                    <strong>{{ onboardingSubdomain }}</strong>
                    <br />---<br />
                  </span>
                </div>
                <div class="well p-d pa-3 mt-1">
                  {{ $t("general.name") }}:
                  <strong>www.{{ domain }}</strong>
                  <br />{{ $t("general.type") }}:
                  <strong>CNAME</strong>
                  <br />{{ $t("general.address") }}:
                  <strong>{{ domain }}</strong>
                </div>
              </li>
              <li>
                {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.third") }}
              </li>
            </ol>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-model="panel" v-if="showNameserversInfo">
          <v-expansion-panel-header class="p-d font-weight-600" disable-icon-rotate>
              {{ $t("heading.instance.modal.setDomain.config.tab.nameservers.title") }}
            <template v-slot:actions>
              <v-icon color="gray" :size="16">$plus</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ol>
              <li>
                {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.first") }}
              </li>
              <li>
                {{ $t("heading.instance.modal.setDomain.config.tab.nameservers.list.second") }}
                <div class="well p-d pa-3 mt-1" v-for="(ns, index) in nameservers" :key="index">
                  <strong>{{ ns }}</strong>
                </div>
              </li>
              <li>
                {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.third") }}
              </li>
              <li>
                <strong>{{ $t("heading.instance.modal.setDomain.config.tab.nameservers.list.note_label") }}</strong>
                {{ $t("heading.instance.modal.setDomain.config.tab.nameservers.list.note_text") }}
              </li>
            </ol>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    domain: String,
    onboardingSubdomain: [String, null],
    dnsSettings: Object,
    hasDnsServer: Boolean,
    ipv4Addresses: Array,
    nameservers: Array,
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      infoTab: null,
      panel: 0,
    };
  },
  computed: {
    displayDnsOptions() {
      let options = this.dnsSettings.display_options;
      if (!Array.isArray(options)) {
        return [];
      }
      return options;
    },
    showARecordInfo() {
      return this.displayDnsOptions.includes("a_records");
    },
    showCNAMERecordInfo() {
      if (!this.onboardingSubdomain) {
        return false;
      }
      return this.displayDnsOptions.includes("cname_record");
    },
    showNameserversInfo() {
      if (!this.hasDnsServer) {
        return false;
      }
      return this.displayDnsOptions.includes("ns_records");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  -webkit-line-clamp: unset;
}

.v-expansion-panel {
  border: 1px solid var(--v-gray-lighten2);

  &::before {
    display: none;
  }

  &::after {
    display: none;
  }

  &--active {
    border-color: var(--v-primary-base);
  }

  .v-expansion-panel-content::v-deep {
    .v-expansion-panel-content__wrap {
      padding-bottom: 0px;
    }
  }

  .v-expansion-panel-header {
    padding-left: 60px;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1 !important;
      background: white;
      border-radius: 20px;
    }

    &::before {
      transition: border-color 0.24s ease, background-color 0.24s ease;
      width: 20px;
      height: 20px;
      border: 1px solid var(--v-gray-base);
    }

    &::after {
      width: 10px;
      height: 10px;
      z-index: 10;
      left: 29px;
    }

    &:hover {
      &::before {
        border-color: #07c07e;
      }
    }

    &--active {
      &:before {
        border-color: #07c07e;
        background-color: #07c07e;
      }
    }
  }
}

.domain-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--v-gray-lighten2);
  padding: 16px 16px;
  margin-bottom: 16px;
}

.domain-info {
  border-radius: 6px;
  border: 1px solid var(--v-gray-lighten2);
  padding: 6px 16px 16px 16px;
  background: #fafafa;
  margin-bottom: 24px;
}

.recovery-code-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: var(--v-primary-base);
  background: rgba(7, 192, 126, 0.05);
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  word-wrap: anywhere;
  padding: 8px;
  min-height: 80px !important;
}
</style>